<template>
  <div>
    <div class="g-wrapper">
      <div class="home-qr">
        <van-image
          class="home-qr-txt"
          width="2.72rem"
          height="1.17rem"
          :src="pic_banner_text"
        ></van-image>
        <div class="home-qr-box home-qr-box-1">
          <img class="home-qr-box-img" :src="pic_wx_qr" />
          <span class="home-qr-box-txt">官方微信群</span>
        </div>
        <div class="home-qr-box home-qr-box-2">
          <img class="home-qr-box-img" :src="pic_qq_qr" @click="addQQ" />
          <span class="home-qr-box-txt">点击加入官方QQ群</span>
        </div>
      </div>

      <!-- 公告列表 -->
      <div class="g-box">
        <van-list>
          <div class="g-item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
            <div class="g-item-title">
              <div class="title one-line">
                {{ item.title }}
              </div>
              <div class="new" v-if="item.read_flag == 0">NEW</div>
            </div>
            <div class="content two-line">
              {{ item.des }}
            </div>
            <div class="content-img" v-if="item.image">
              <img :src="item.image" alt="" />
            </div>
            <div class="g-bottom">
              <div class="time">{{ timeChange(item.created_time) }}</div>
              <div class="btn">查看详情 <van-icon name="arrow" /></div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <PageLoading :show="loading" />
  </div>
</template>

<script>
import navBar from '@/components/NavBar';
import { reactive, toRefs, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getnoticelist, getnoticeinfo } from '@/service/home';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import { useStore } from 'vuex';
import { Toast } from 'vant';
export default {
  name: 'g',
  components: {
    navBar,
    PageLoading
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      list: [],
      loading: false
    });

    onMounted(async () => {
      const { data } = await getnoticelist();
      state.list = data;
      state.loading = false;
      setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }, 200);
      // History.scrollRestoration;
    });

    // 自动加QQ群
    const addQQ = () => {
      window.location.href =
        'https://qm.qq.com/cgi-bin/qm/qr?k=sBLr_awKhhIrMxbnLQB8noXbx3dyIhUD&jump_from=webapi';
    };
    const toDetail = async item => {
      // 判断是否有链接，有链接跳转链接
      if (item.link_url) {
        Toast.loading({
          message: '加载中',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        const { data } = await getnoticeinfo({ id: item.id });
        await store.dispatch('getnoticereadstatus');
        Toast.clear();
        location.href = item.link_url;
      } else {
        router.push({ path: '/announcement/detail', query: { id: item.id } });
      }
    };

    const timeChange = time => {
      return moment(time).format('YYYY-MM-DD');
    };

    return {
      ...toRefs(state),
      addQQ,
      toDetail,
      timeChange,
      pic_qq_qr: require('../../imgs/comm/pic_qq_qr.png'),
      pic_wx_qr: require('../../imgs/comm/pic_wx_qr.png'),
      pic_banner_text: require('../../imgs/comm/pic_home_banner_txt.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.five-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.g-box {
  margin-top: 24px;
  .g-item {
    margin-top: 16px;
    padding-bottom: 16px;

    border-bottom: 1px solid #494359;
    .g-item-title {
      @include fj();
      align-items: center;
      .title {
        font-size: 16px;
        font-weight: 600;
        max-width: 85%;
      }
      .new {
        font-size: 12px;
        font-weight: 500;
        color: #1c172a;
        padding: 2px 8px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        border-radius: 4px 4px 4px 4px;
        //line-height: 16px;
      }
    }
    .content {
      font-size: 14px;
      margin-top: 8px;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.7);
    }
    .content-img {
      margin-top: 10px;
      img {
        width: 100%;
        border-radius: 2px;
      }
    }
    .g-bottom {
      margin-top: 6px;
      @include fj();
      align-items: center;
      .time {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
      }
      .btn {
        font-size: 14px;
        color: $primary;
      }
    }
  }
}
.g-wrapper {
  padding: 12px 20px 80px 20px;
}
.home-qr {
  display: flex;
  justify-content: center;
  // margin-left: 27px;
  // margin-right: 27px;
  margin-top: 3px;
  height: 99px;
  font-size: 12px;
  align-items: center;
  background-image: url('../../imgs/comm/pic_home_banner_bg.png');
  background-size: 100% 100%;
  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-txt {
      // position: absolute;
      font-size: 12px;
      transform: scale(0.75);
      white-space: nowrap;
      // margin-top: 90px;
      opacity: 0.8;
      word-break: break-all;
    }
    &-img {
      width: 73px;
      height: 73px;
      pointer-events: auto;
    }
  }
  &-box-1 {
    width: 73px;
    margin-left: 30px;
    // margin-bottom: 10px;
  }
  &-box-2 {
    width: 94px;
    margin-left: 11px;
    // margin-bottom: 10px;
  }
}
</style>
